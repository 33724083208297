import React, { useEffect, useState } from "react";
import "../../App";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import shareImage from "../../Images/imgShare.png";

import firebase from "../../Firebase";
const ref = firebase.firestore().collection("TaxAdvisor");
let newCalculation = {};
let longLat = "";
let location = "";

//firebase saving
function pushCalculation(newCalculation) {
  let nowDate = new Date();
  let id =
    nowDate.getFullYear() +
    "" +
    nowDate.getMonth() +
    "" +
    nowDate.getDate() +
    "" +
    nowDate.getHours() +
    "" +
    nowDate.getMinutes();

  // console.log(newCalculation);
  ref
    .doc(id)
    .set(newCalculation)
    .then(() => {
      console.log("tax calculation added to db");
    });
}

export default function Privacy() {
  const [userCountry, setUserCountry] = useState("");
  const [userIp, setUserIp] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [userLongLat, setUsserLongLat] = useState("");

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "windows";
    }

    if (/android/i.test(userAgent)) {
      return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
    return "desktop";
  }

  let iosStoreURL = "itms-apps://itunes.apple.com/app/1599221145";
  let androidStoreURL =
    "https://play.google.com/store/apps/details?id=com.shrestha.MPC";

  var os = "desktop";
  useEffect(() => {
    fetch(
      "https://api.ipdata.co?api-key=f4b95b23c6fd14b5e7cdec941323fa4ae3659db3fe6e0e5c6f9bc874"
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("User data pull complete");
        longLat = res.latitude + "," + res.longitude;
        location = res.region + ", " + res.city;
        setUserCountry(res.country_name);
        setUserLocation(location);
        setUsserLongLat(longLat);
        setUserIp(res.ip);

        newCalculation = {
          userLongLat,
          userLocation,
          userCountry: 0,
          salaryType: 0,
          maritalStatus: 0,
          salary: 0,
          bonus: 0,
          months: 0,
          annualGrossSalary: 0,
          pfPer: 0,
          PFContributionByEmployer: 0,
          cit: 0,
          lifeInsurance: 0,
          healthInsurance: 0,
          donation: 0,
          totalDeduct: 0,
          totalDeductAfterAdvice: 0,
          taxableSal: 0,
          taxableSalAfterAdvice: 0,
          taxAmount: 0,
          taxAmountAfterAdvice: 0,
          taxAmountAfterAdviceAndMarry: 0,
          from: Intl.DateTimeFormat().resolvedOptions().timeZone,
          userIp,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        };

        pushCalculation(newCalculation);
      });

    os = getMobileOperatingSystem();
    if (os === "ios") {
      window.location.href = iosStoreURL;
    } else if (os === "android") {
      window.location.href = androidStoreURL;
    } else {
      console.log("Share page is loaded in desktop browser.");
    }
  }, []);

  return (
    <>
      <Navbar />
      <div class="body-wrapper container">
        <h5>Click open to download the app.</h5>
        <br />
        <h5>OR</h5>
        <br />
        <h5>Scan the QR code on your mobile device to download.</h5>
        <div class="row">
          <div class="col-sm">
            <img src={shareImage} alt="MPC logo" className="MPCLogo" />
          </div>
        </div>
        <br />
        <h5>If open button does not appear then click the link bellow.</h5>
        <div class="row">
          <div class="col-sm">
            <h5 style={{ marginTop: "20px" }}>
              iPhone/iPad:{" "}
              <a href={iosStoreURL} target="_blank" rel="noopener noreferrer">
                Click here to download
              </a>
            </h5>
            <h5 style={{ marginTop: "20px" }}>
              Android:{" "}
              <a
                href={androidStoreURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to download
              </a>
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
